import { salesAxios } from '../config/axiosInstances';
import {
  FindClosingStatusRequest,
  FindClosingStatusResponse,
  MissingCauses,
  Permission,
} from '../types';

export type GetPermissionsResponse = Permission[];

export type GetOpStructuresResponse = {
  division_id: string;
  division: string;
  country_id: number;
  country: string;
  regional_mgmt_id: string;
  regional_mgmt: string;
  region_id: string;
  region: string;
  mgmt_id: string;
  mgmt: string;
  supervision_id: string;
  supervision: string;
  store_entity_id: number;
  store_acronym: string;
  company_id: number;
  company_name: string;
  agreement_id: string;
  agreement_type: string;
}[];

export interface FindSalesRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  accountingDateRange?: string;
  operationDateRange?: string;
  saleType?: string;
  posType?: string;
  posNum?: string;
  timeRange?: string;
  timeBand?: string;
  regionalMgmtId?: string;
  regionId?: string;
  mgmtId?: string;
  supervisionId?: string;
  agreementId?: string;
  companyId?: string;
  pod?: string;
  groupBy?: string;
  sentToOnedrive?: boolean;
  accountingEntriesFields?: boolean;
}

export type FindSalesResponse = {
  country: string;
  store: string;
  business_date: string;
  accounting_date: string;
  operation_date: string;
  sale_type_wh?: number;
  pos_type?: string;
  pos_num?: number;
  time_band?: string;
  day_part?: string;
  regional_mgmt_id: string;
  region_id: string;
  mgmt_id: string;
  supervision_id: string;
  agreement_id: string;
  company_id: string;
  net_sale_pos: number;
  tax1_pos: number;
  tax2_pos: number;
  tax3_pos: number;
  gross_sale_pos: number;
  net_sale_final_pos: number;
  net_sale_third_party: number;
  tax1_third_party: number;
  tax2_third_party: number;
  tax3_third_party: number;
  gross_sale_third_party: number;
  net_sale_np: number;
  tax1_np: number;
  tax2_np: number;
  tax3_np: number;
  gross_sale_np: number;
  net_sale_final_np: number;
  net_sale_pnp: number;
  tax1_pnp: number;
  tax2_pnp: number;
  tax3_pnp: number;
  gross_sale_pnp: number;
  net_sale_final_pnp: number;
  net_sale_p: number;
  tax1_p: number;
  tax2_p: number;
  tax3_p: number;
  gross_sale_p: number;
  net_sale_final_p: number;
  gcs: number;
  gcs_avg: number;
  accounting_store_id?: number;
  store_id?: number;
  discount_sale_pos?: number;
  mapacaixasales?: number;
  aliquota0sales?: number;
  loyalty?: number;
  icms?: number;
  pis?: number;
  cofins?: number;
  brindessales_hmb?: number;
  icmsbrindes_hmb?: number;
  pisbrindes_hmb?: number;
  cofinsbrindes_hmb?: number;
  tasadeentrega?: number;
  icmsbrindes_t?: number;
  pisbrindes_t?: number;
  cofinsbrindes_t?: number;
  brindessales_v?: number;
  icmsbrindes_v?: number;
  pisbrindes_v?: number;
  cofinsbrindes_v?: number;
}[];

export interface FindGCsBaseRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  accountingDateRange?: string;
  operationDateRange?: string;
  timeRange?: string;
  posType?: string;
  posNum?: string;
  groupBy?: string;
  sentToOnedrive: boolean;
}

export interface FindGCsBaseResponse {
  country?: string;
  country_id?: string;
  store: string;
  store_id?: number;
  accounting_date: string;
  business_date: string;
  operation_date: string;
  pos_num: number;
  pos_type: string;
  transaction_type: number;
  end_of_sale_timestamp: string;
  day_part: string;
  receipt_num: number;
  invoice_type: string;
  invoice_kind: string;
  fiscal_pos_code: string;
  fiscal_code5: number;
  fiscal_code4: number;
  fiscal_name1?: string;
  fiscal_name2?: string;
  fiscal_address1?: string;
  fiscal_address2?: string;
  transaction_amt: number;
  tax_amt: number;
  net_amt: number;
  special_sale_order_id?: string;
  special_sale_type: string;
  special_sale_method?: string;
  operator_id?: number;
  manager_id?: number;
  sale_key?: string;
  wild_card_alfanum1?: string;
}

export type FindGcsRequest = FindGCsBaseRequest & {
  saleDiscount?: string;
  pod?: string;
  discountsJoin?: string;
};

export type FindGcsResponse = (FindGCsBaseResponse & {
  accounting_store_id?: number;
  fiscal_receipt_num: number;
  fiscal_register_id?: string;
  wild_card_alfanum4?: string;
  wild_card_alfanum5?: string;
  wild_card_numeric1?: number;
  discount_on_total_amt?: number;
  sale_discount_id?: number;
  sale_discount_description?: string;
  sale_discount_rate?: number;
  sale_discount_amount?: number;
})[];

export type FindGcsByPaymentTypesRequest = FindGCsBaseRequest & {
  operationType?: string;
  tenderName?: string;
};

export type FindGcsByPaymentTypesResponse = (FindGCsBaseResponse & {
  id: number;
  seq_no: number;
  fiscal_receipt_num: number;
  operation_type_name: string;
  sale_description: string;
  begin_of_sale_timestamp: string;
  special_sale_type: string;
  tender_name: string;
  tender_type: number;
  amount: number;
  wild_card_numeric1?: number;
  epayment_id?: string;
  epayment_coupon?: string;
  epayment_terminal?: string;
  epayment_store_id?: string;
  epayment_card_num?: string;
})[];

export type FindGCsByProductRequest = FindGCsBaseRequest & {
  plu?: string;
  saleType?: string;
  saleDiscount?: string;
  itemDiscount?: string;
  discountsJoin?: string;
};

export type FindGCsByProductResponse = (FindGCsBaseResponse & {
  seq_no?: number;
  sale_type_wh?: number;
  fiscal_receipt_num?: number;
  sale_description?: string;
  plu?: number;
  short_name?: string;
  items_qty?: number;
  unit_price_amt?: number;
  item_status_name?: string;
  non_product_price?: number;
  item_total_amt?: number;
  tax_amt?: number;
  tax_np_amt?: number;
  thirdparty_menu_item?: boolean;
  venta_np_neta_iva?: number;
  wild_card_alfanum4?: string;
  wild_card_alfanum5?: string;
  wild_card_numeric1?: number;
  discount_on_total_amt?: number;
  sale_discount_id?: number;
  sale_discount_description?: string;
  sale_discount_rate?: number;
  sale_discount_amount?: number;
  item_discount_id?: number;
  item_discount_description?: string;
  item_discount_rate?: number;
  item_discount_amount?: number;
  begin_of_sale_timestamp: string;
})[];

export interface FindClosingRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  causes?: string;
  federativeEntities?: string;
  franchiseTypes?: string;
}

export type FindClosingResponse = {
  country: string;
  store: string;
  business_date: string;
  federative_entity: string | null;
  franchise_type: string | null;
  cause: string[];
}[];

export interface FindMissingClosingCausesRequest {
  countryAcronym: string;
  storeAcronym: string;
  accountingDate?: string;
  cause: string;
}

export type ValidationErrorCauseResponse = {
  error_type?: string;
  code?: number;
  pos_num?: number;
  sequence?: number;
  element_type?: number;
  pending_errors?: Record<string, string>[];
};

export type DifferencesCauseResponse = {
  pos_num: number;
  sequence?: number;
  close_amt?: number;
  close_gc_qty?: number;
  detail_amt: number;
  detail_gc_qty: number;
};

export type FindMissingClosingCausesResponse = {
  country: string;
  store: string;
  business_date: string;
  cause: string;
  details: DifferencesCauseResponse[] | ValidationErrorCauseResponse[];
};

export interface FindCompanyCodesRequest {
  countryId: string;
}

export interface GetCompanyCodeRequest {
  countryId: string;
  companyCodeWh: number;
}

export type FindCompanyCodesResponse = {
  country_id: string;
  name: string;
  company_code_wh: string;
  company_code_ebs?: string;
  ledger?: string;
  currency?: string;
  class_code?: number;
  company_oak_id?: number;
  is_accounted?: boolean;
}[];

export type GetCompanyCodeResponse = {
  country_id: string;
  name: string;
  company_code_wh: string;
  company_code_ebs?: string;
  ledger?: string;
  currency?: string;
}[];

export interface EditCompanyCodeRequest {
  countryId: string;
  name: string;
  companyCodeWh: string;
  classCode: number;
  companyOakId: number;
  isAccounted: boolean;
  companyCodeEbs?: string;
  currency?: string;
  ledger?: string;
}

export interface FindAccountingParametersRequest {
  countryId?: string;
  journalType?: string;
}

export type FindAccountingParametersResponse = {
  country_id: string;
  journal_type: string;
  source: string;
  category?: string;
  category_alias?: string;
  frequency: string;
  details: {
    journal_subtype: string;
    account: string;
    sub_account: string;
  }[];
}[];

export interface EditAccountingParametersRequest {
  countryId: string;
  journalType: string;
  frequency?: string;
  details: {
    journalSubtype: string;
    account?: string;
    subAccount?: string;
  }[];
}

export interface FindAccountingEntriesRequest {
  countryId?: string;
  accountingMonth?: string;
  journalType?: string | null;
  status?: string | null;
}

export type FindAccountingEntriesResponse = {
  id: number;
  country_id: string;
  accounting_month: string;
  journal_type: string;
  journal_group: string;
  status: string;
  created_date: string;
}[];

export interface CreateAccountingEntriesRequest {
  countryId: string;
  accountingMonth: string;
  journalGroup: string;
  countryCode: string;
}

export interface DeleteAccountingEntriesRequest {
  countryId: string;
  accountingMonth: string;
  journalGroup: string;
  comments: string;
}

export interface AccountingEntriesReportRequest {
  id: number;
}

export type AccountingEntriesReportResponse = {
  country_id: string;
  journal_type: string;
  set_of_books_id: number;
  currency_code: string;
  accounting_date: string;
  company_id: number;
  company_name: string;
  journal_subtype: string;
  account: string;
  sub_account: string;
  cost_center: string;
  store: string;
  credit: string;
  debit: string;
}[];

export interface FindMonthClosingRequest {
  countryAcronym: string;
}

export interface FindClosedMonthsRequest {
  countryAcronym: string;
  accountingMonth: string;
}

export type FindMonthClosingResponse = {
  country: string;
  accounting_month: string;
  status: string;
  justification: string;
  created_by: string;
  creation_date: string;
  updated_by: string;
  update_date: string;
}[];

export interface CloseMonthRequest {
  countryAcronym: string;
  accountingMonth: string;
  createdBy: string;
}

export interface AnnulMonthClosingRequest {
  countryAcronym: string;
  accountingMonth: string;
  updatedBy: string;
  justification: string;
}

export interface FindDailySalesRequest {
  countryAcronym: string;
}

export type FindDailySalesResponse = {
  country: string;
  gcs: number;
  pos_net_sale: number;
}[];

export interface FindDelayedStoresRequest {
  countryAcronym: string;
}

export type FindDelayedStoresResponse = {
  country: string;
  store: string;
  last_sale_dttm: string;
  current_dttm: string;
}[];

export interface FindReportTenderRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  accountingDateRange?: string;
  operationDateRange?: string;
  saleType?: string;
  paymentTypeId?: string;
  operationType?: string;
  tenderName?: string;
  posType?: string;
  posNum?: string;
  timeRange?: string;
  timeBand?: string;
  regionalMgmtId?: string;
  regionId?: string;
  mgmtId?: string;
  supervisionId?: string;
  agreementId?: string;
  companyId?: string;
  groupBy?: string;
  sentToOnedrive: boolean;
}

export type FindReportTenderResponse = {
  country: string;
  store?: string;
  business_date?: string;
  accounting_date?: string;
  operation_date?: string;
  pos_type?: string;
  pos_num?: number;
  time_band?: string;
  day_part?: string;
  sale_type_wh?: number;
  payment_type_id?: number;
  operation_type_name?: string;
  tender_name?: string;
  tender_description?: string;
  regional_mgmt_id?: string;
  region_id?: string;
  mgmt_id?: string;
  supervision_id?: string;
  agreement_id?: string;
  company_id?: string;
  total_gross_sales: number;
  total_net_sales: number;
  extra_cash: number;
  promotion: number;
  sale_original_foreign_currency: number;
  average_price: number;
}[];

export interface FindGrossSaleRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  paymentTypeId?: string;
  groupBy?: string;
}

export type FindGrossSaleResponse = {
  country: string;
  store?: string;
  business_date?: string;
  gross_sale: number;
}[];

export interface FindCancellationsRequest {
  countryAcronym: string;
  storeAcronym?: string;
  accountingDateRange?: string;
  groupBy?: string;
}

export type FindCancellationsResponse = {
  country: string;
  store: string;
  accounting_date: string;
  operation: string;
  quantity: number;
  amount: number;
  pos_gross_sale: number;
  percent: number;
}[];

export interface FindOakBrookRequest {
  countryAcronym: string;
  monthYear: string;
}

export type FindOakBrookResponse = {
  class_code: string;
  country_id: string;
  oak_store_id: string;
  company_oak_id: string;
  date: string;
  sales: number;
  gcs: number;
}[];

export interface FindSalesByProductRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  accountingDateRange?: string;
  operationDateRange?: string;
  plu?: string;
  timeRange?: string;
  timeBand?: string;
  posNum?: string;
  posType?: string;
  saleType?: string;
  regionalMgmtId?: string;
  regionId?: string;
  mgmtId?: string;
  supervisionId?: string;
  agreementId?: string;
  companyId?: string;
  groupBy?: string;
  sentToOnedrive: boolean;
}

export type FindSalesByProductResponse = {
  store?: string;
  accounting_date?: string;
  business_date?: string;
  operation_date?: string;
  pos_type?: string;
  sale_type_wh?: number;
  pos_num?: number;
  day_part?: string;
  time_band?: string;
  plu?: number;
  short_name?: string;
  quantity_sold?: number;
  quantity_sold_local?: number;
  quantity_sold_delivery?: number;
  quantity_waste?: number;
  quantity_promo?: number;
  quantity_meal?: number;
  net_sale_pos?: number;
  gross_sale_pos?: number;
  net_sale_final_pos?: number;
  net_sale_third_party?: number;
  gross_sale_third_party?: number;
  net_sale_np?: number;
  gross_sale_np?: number;
  net_sale_final_np?: number;
  net_sale_pnp?: number;
  gross_sale_pnp?: number;
  net_sale_final_pnp?: number;
  net_sale_p?: number;
  gross_sale_p?: number;
  net_sale_final_p?: number;
}[];

export interface FindThirdPartySalesByItemsRequest {
  countryAcronym: string;
  storeAcronym?: string;
  accountingDateRange?: string;
  plu?: string;
  groupBy?: string;
  sentToOnedrive: boolean;
}

export type FindThirdPartySalesByItemsResponse = {
  store?: string;
  accounting_date?: string;
  plu?: number;
  short_name?: string;
  date_from: string;
  date_to: string;
  quantity_sold: number;
  quantity_promo: number;
  quantity_meal: number;
  quantity_waste: number;
  gross_sale_ad: number;
  calculated_net_sale: number;
  net_sale_ad: number;
  sale_mix_percentage: number;
}[];

export interface FindMastersStatusDetailsRequest {
  importDate?: string;
}

export type FindMastersStatusDetailsResponse = {
  master_type: string;
  creation_date?: string;
  error?: string;
  register_qty: number;
  status: string;
}[];

export type FindMastersStatusResponse = {
  import_date: string;
  status: string;
}[];

export interface FindSalesJournalRequest {
  countryAcronym: string;
  storeAcronym?: string;
  accountingDateRange?: string;
  dataType: string;
}

export type FindSalesJournalResponse = {
  country_id?: string;
  store?: string;
  accounting_date?: string;
  fiscal_pos_code?: string;
  pos_num?: number;
  from?: number;
  to?: number;
  invoice_type?: string;
  invoice_kind?: string;
  fiscal_receipt_num?: number;
  fiscal_name?: string;
  fiscal_register_id?: string;
  gross_sale?: number;
  iva?: number;
  iva_percentage?: number;
  net_sale?: number;
}[];

export interface FindMastersImportRequest {
  countries?: string;
  importDateRange?: string;
  state?: string;
}

export type FindMastersImportResponse = {
  import_date: string;
  type: string;
  id: string;
  description: string;
  quantity: number;
  state: string;
}[];

export interface MastersImportRequest {
  master_type?: string[];
  countries?: string[];
  days?: number;
  only_active?: boolean;
}

export interface FindPendingInterfacesRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  queryFilter?: string;
}

export type FindPendingInterfacesResponse = {
  country: string;
  store: string;
  business_date: string;
  data_entry?: string;
  working_day?: boolean;
  sales_close?: string;
  sales_cause?: string;
  sales_details?: string;
  coll_close?: string;
  coll_cause?: string;
  coll_details?: string;
  wh_data?: string;
  gr_sales_processed?: string;
  gr_sales_sent?: string;
  gr_status?: string;
  grouped_total_sales?: string;
  grouped_sales_by_payment?: string;
  grouped_sales_by_product?: string;
  last_update?: string;
}[];

export interface CreatePendingInterfacesRequest {
  countryAcronym: string;
  stores: string[];
  businessDateFrom: string;
  businessDateTo: string;
  justification: string;
}

export interface ExecutionPendingInterfacesRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDate?: string;
  state?: string;
}

export type ExecutionPendingInterfacesResponse = {
  country: string;
  store: string;
  business_date: string;
  justification: string;
  execution_date: Date;
  status: string;
}[];

export interface FindPendingInterfacesParametersResponse {
  statuses: string[];
  filterQuerys: string[];
}

export default class {
  static async getPermissions() {
    return salesAxios.get<GetPermissionsResponse>('/permissions');
  }

  static async getOpStructures() {
    return salesAxios.get<GetOpStructuresResponse>('/op-structures');
  }

  static async findClosingSalesStatus(request: FindClosingStatusRequest) {
    return salesAxios.get<FindClosingStatusResponse>(`/closing_status`, { params: request });
  }

  static async findSales(request: FindSalesRequest) {
    return salesAxios.get<FindSalesResponse>(`/sales`, { params: request });
  }

  static async findGcs(request: FindGcsRequest) {
    return salesAxios.get<FindGcsResponse>(`/report_gcs`, { params: request });
  }

  static async findGcsByPaymentTypes(request: FindGcsByPaymentTypesRequest) {
    return salesAxios.get<FindGcsByPaymentTypesResponse>(`/detail_gcs_payment_types`, {
      params: request,
    });
  }

  static async findClosing(request: FindClosingRequest) {
    return salesAxios.get<FindClosingResponse>(`/missing_closing_detail`, {
      params: request,
    });
  }

  static async findCauseTypes() {
    return salesAxios.get<MissingCauses[]>(`/missing_closing_causes_types`);
  }

  static async findMissingClosingCauses(request: FindMissingClosingCausesRequest) {
    return salesAxios.get<FindMissingClosingCausesResponse>(`/missing_closing_causes`, {
      params: request,
    });
  }

  static async findCompanyCodes(request: FindCompanyCodesRequest) {
    return salesAxios.get<FindCompanyCodesResponse>(`/accounting-entries/companies`, {
      params: request,
    });
  }

  static async getCompanyCode(request: GetCompanyCodeRequest) {
    return salesAxios.get<GetCompanyCodeResponse>(`/accounting-entries/companies`, {
      params: request,
    });
  }

  static async editCompanyCodes(data: EditCompanyCodeRequest) {
    return salesAxios.post(`/accounting-entries/companies`, data);
  }

  static async findAccountingParameters(request: FindAccountingParametersRequest) {
    return salesAxios.get<FindAccountingParametersResponse>(`/accounting-entries/parameters`, {
      params: request,
    });
  }

  static async editAccountingParameters(data: EditAccountingParametersRequest) {
    return salesAxios.post(`/accounting-entries/parameters`, data);
  }

  static async findAccountingEntries(request: FindAccountingEntriesRequest) {
    return salesAxios.get<FindAccountingEntriesResponse>(`/accounting-entries`, {
      params: request,
    });
  }

  static async createAccountingEntries(data: CreateAccountingEntriesRequest) {
    return salesAxios.post(`/accounting-entries`, data);
  }

  static async deleteAccountingEntries(data: DeleteAccountingEntriesRequest) {
    return salesAxios.delete(`/accounting-entries`, { data });
  }

  static async accountingEntriesReport(request: AccountingEntriesReportRequest) {
    return salesAxios.get<AccountingEntriesReportResponse>(`/accounting-entries/report`, {
      params: request,
    });
  }

  static async findMonthClosing(request: FindMonthClosingRequest) {
    return salesAxios.get<FindMonthClosingResponse>(`/month_closing/closed_months`, {
      params: request,
    });
  }

  static async closeMonth(data: CloseMonthRequest) {
    return salesAxios.post(`/month_closing/close_month`, data);
  }

  static async annulMonthClosing(data: AnnulMonthClosingRequest) {
    return salesAxios.post(`/month_closing/annul_closing`, data);
  }

  static async findClosedMonth(request: FindClosedMonthsRequest) {
    return salesAxios.get<FindMonthClosingResponse>(`/month_closing/closed_month`, {
      params: request,
    });
  }

  static async findDailySales(request: FindDailySalesRequest) {
    return salesAxios.get<FindDailySalesResponse>(`/daily_sales`, { params: request });
  }

  static async findStoresLastSale(request: FindDelayedStoresRequest) {
    return salesAxios.get<FindDelayedStoresResponse>(`/stores_last_sale`, { params: request });
  }

  static async findReportTender(request: FindReportTenderRequest) {
    return salesAxios.get<FindReportTenderResponse>(`/report-tender`, {
      params: request,
    });
  }

  static async findCancellations(request: FindCancellationsRequest) {
    return salesAxios.get<FindCancellationsResponse>(`/cancellations`, {
      params: request,
    });
  }

  static async findGrossSale(request: FindGrossSaleRequest) {
    return salesAxios.get<FindGrossSaleResponse>(`/gross_sale`, {
      params: request,
    });
  }

  static async findOakBrook(request: FindOakBrookRequest) {
    return salesAxios.get<FindOakBrookResponse>(`/oak-brook`, {
      params: request,
    });
  }

  static async findSalesByProduct(request: FindSalesByProductRequest) {
    return salesAxios.get<FindSalesByProductResponse>(`/sales-product`, { params: request });
  }

  static async findGCsByProduct(request: FindGCsByProductRequest) {
    return salesAxios.get<FindGCsByProductResponse>(`/gcs-product`, { params: request });
  }

  static async findThirdPartySalesByItems(request: FindThirdPartySalesByItemsRequest) {
    return salesAxios.get<FindThirdPartySalesByItemsResponse>(`/third-party-sales-by-items`, {
      params: request,
    });
  }

  static async findMastersStatus() {
    return salesAxios.get<FindMastersStatusResponse>('/masters_status');
  }

  static async findMastersStatusDetails(request: FindMastersStatusDetailsRequest) {
    return salesAxios.get<FindMastersStatusDetailsResponse>(`/masters_status_details`, {
      params: request,
    });
  }

  static async findSalesJournal(request: FindSalesJournalRequest) {
    return salesAxios.get<FindSalesJournalResponse>(`/sales_journal`, {
      params: request,
    });
  }

  static async findMastersImport(request: FindMastersImportRequest) {
    return salesAxios.get<FindMastersImportResponse>(`/masters_status_details_new`, {
      params: request,
    });
  }

  static async findMastersStatus2() {
    return salesAxios.get<FindMastersStatusResponse>('/masters_status_new');
  }

  static async mastersImportExecution(data: MastersImportRequest) {
    return salesAxios.post(`/masters/import`, data);
  }

  static async findPendingInterface(request: FindPendingInterfacesRequest) {
    return salesAxios.get<FindPendingInterfacesResponse>(`/pending_interfaces`, {
      params: request,
    });
  }

  static async createPendingInterfaces(data: CreatePendingInterfacesRequest) {
    return salesAxios.post(`/executions_pending_interfaces`, data);
  }

  static async executionsPendingInterfaces(request: ExecutionPendingInterfacesRequest) {
    return salesAxios.get<ExecutionPendingInterfacesResponse>(`/executions_pending_interfaces`, {
      params: request,
    });
  }

  static async findPendingInterfacesParameters() {
    return salesAxios.get<FindPendingInterfacesParametersResponse>(
      `/pending_interfaces/parameters`
    );
  }
}
